<template>
  <div class="app">
    <el-carousel height="37.5rem" v-if="carouselList.length > 0">
      <el-carousel-item v-for="item in carouselList" :key="item.id">
        <img :src="imgPic + item.fileUri" width="100%" height="600px" alt="" />
      </el-carousel-item>
    </el-carousel>
    <el-carousel height="600px" v-else>
      <el-carousel-item v-for="(obj, index) in defalutList" :key="index">
        <img :src="obj.fileUri" width="100%" height="600px" alt="" />
      </el-carousel-item>
    </el-carousel>
    <!-- 图片 -->
    <!-- <img class="picmsg" src="../../../assets/allImage/serviceNetworkStation//toutu-fuwuwangdain@1x.png" alt="" /> -->
    <!-- 导航 -->
    <div class="profilephotoNav">
      <div class="proSon">
        <img
          class="pic"
          src="../../../assets//allImage//companyProfile/icon_home@2x.png"
          alt=""
        />
        <div style="font-size: 0.9375rem; cursor: pointer" @click="GoHome">
          首页
        </div>
        <i class="iconfont icon-xiangyoujiantou"></i>
        <div style="font-size: 0.9375rem">{{ columnsName }}</div>
      </div>
    </div>
    <div class="app-content">
      <!-- 搜索 -->
      <div class="searchSlit">
        <el-input
          v-model="form.title"
          style="width: 12.5rem; margin-right: 0.9375rem"
          placeholder="请输入网点名称"
          clearable
          @keyup.enter.native="searchFn"
        ></el-input>
        <el-select v-model="value" placeholder="请选择" @change="changeFn">
          <el-option
            style="padding-left: 10px"
            v-for="(item, inde) in mapListClassification"
            :key="inde"
            :label="item.typeName"
            :value="item.uuId"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          @click="searchFn"
          icon="el-icon-search"
          style="width: 5rem; height: 2.1875rem; margin-left: 0.9375rem"
          >搜索</el-button
        >
      </div>
      <!-- 地图 -->
      <div class="ma-_content">
        <div class="map_query">
          <div
            class="businessType"
            v-for="(item, insd) in networkList"
            :key="insd"
          >
            <!-- 列表标题 -->
            <div class="Nav_title" v-if="item.networkName">
              <span @click="ListTitleFn(item)" style="cursor: pointer">{{
                item.networkName
              }}</span>
              <!-- 0 暂停 1正常 -->
              <span
                class="pause"
                :style="{
                  backgroundColor: item.status == 0 ? '#FA561F' : '#4FC975',
                }"
                >{{ item.status == 0 ? '暂停营业' : '正常营业' }}</span
              >
            </div>
            <div class="add_parent">
              <div class="adds" v-if="item.address">
                地址：{{ item.address }}
              </div>
              <div class="adds" v-if="item.phone">电话：{{ item.phone }}</div>
              <div class="adds" v-if="item.businessHours">
                营业时间：{{ item.businessHours }}
              </div>
              <div class="adds" v-if="item.serviceScope">
                服务范围：{{ item.serviceScope }}
              </div>
            </div>
          </div>
          <div class="businessType" v-if="networkList.length == 0">
            暂无数据
          </div>
        </div>
        <div class="Maps" ref="mapContainer">
			<img :src="mapImg" class="map_img"/>
		</div>
      </div>
    </div>
    <!-- 底部 -->
    <BottomField></BottomField>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { dotNickname, slideshowList } from '../../../api/index';
import { searchNetwork, branchclassify } from '@/api/NetworkStation/index.js';
import BottomField from '@/layout/components/HomePage/BottomField/index.vue';
export default {
  components: {
    BottomField,
  },
  data() {
    return {
      mapListClassification: [], //网点分类
      imgPic: process.env.VUE_APP_BASE_URL,
      carouselList: [],
      defalutList: [
        {
          fileUri: require('@/assets/allImage/defaultCarouselChart/toutu-fuwuwangdain@1x.png'),
        },
      ],
      networkList: [], //网点信息
      columnsName: '', // 网点昵称
      center: {
        lng: 0,
        lat: 0,
      },
      OfflineNumber: 0,
      OnlineNumber: 0,
      zoom: 3,
      imgone: require('../../../assets/allImage/serviceNetworkStation/jiancedain.png'),
      // imgtwo: require('../../../assets/comm/jiancedainoff.png'),
      mapInfo: [], //返回的数据
      checkPointId: '', //  // 检测点id
      infoWindow: {
        show: false,
        info: {},
      },
      input: '',
      options: [],
      dotList: [],
      value: '',
      //商家信息(包含坐标信息)
      businessDetail: {},
      //地图数据
      mapData: {
        //中心坐标
        center: {
          lng: 0,
          lat: 0,
        },
        //缩放级别
        zoom: 13,
      },
      markers: [], // 标记数组
      //地图对象
      map: null,
      bmap: null,
      form: {
        netWorkType: '', // uuid
        selfLon: '108.37',
        selfLat: '22.82',
        title: '',
        id: '',
      },
      newform: {
        netWorkType: '', // uuid
        selfLon: '108.37',
        selfLat: '22.82',
        title: '',
        id: '',
      },
	  mapImg:require('../../../assets/allImage/serviceNetworkStation/map_all.png'),
    };
  },
  async created() {
    window.JumpAddress = this.JumpAddress;
    const res = await dotNickname();
    if (res.data) {
      if (res.data.length > 0) {
        res.data.map((item) => {
          if (item.isRegular == 3) {
            this.columnsName = item.columnsName;
          }
        });
      }
    }
  },
  mounted() {
    this.GetBranchclassify();
    this.GetNetworkList();
    // 初始化数据
    this.$store.dispatch('mapFn');
    //this.$store.dispatch('Dottion');
    this.checkLocationPermission();
  },
  watch: {
    '$route.query': {
      immediate: true,
      async handler(newVal, oldVal) {
        this.PicListslideshowList(newVal.id);
        // 在这里处理id参数的变化
        if (newVal.networkName) {
          this.form.title = decodeURIComponent(newVal.networkName);
          this.form.selfLon = '108.37345083';
          this.form.selfLat = '22.8226066';
          await this.searchFn();
          console.log('T', this.networkList);

          setTimeout(() => {
            this.ListTitleFn(this.networkList[0]); //定位到第一个
          }, 500);
        } else {
          this.form = {
            netWorkType: '', // uuid
            selfLon: '108.37345083',
            selfLat: '22.8226066',
            title: '',
            id: '',
          };
          this.searchFn();
        }
      },
    },
  },

  methods: {
    // 轮播图
    async PicListslideshowList(newTitle) {
      const res = await slideshowList(newTitle);
      if (res.data.code == 200) {
        if (res.data.data && res.data.data.length > 0) {
          this.carouselList = res.data.data;
        }
      } else {
        this.carouselList = [];
      }
    },
    async GetBranchclassify() {
      const res = await branchclassify();
      if (res.data.code == 200) {
        let emptyclass = { typeName: '全部', uuId: '' };
        this.mapListClassification = res.data.data;
        this.mapListClassification.unshift(emptyclass);
      }
    },
    async GetNetworkList() {
      const res = await searchNetwork(this.form);
      if (res.data.code == 200) {
        this.networkList = res.data.data;
        //this.initMap();
		
		//切换地图图片
		if(this.form.netWorkType==""){ //全部
			this.mapImg=require('../../../assets/allImage/serviceNetworkStation/map_all.png');
		}else if(this.form.netWorkType=="0e67549a-84b5-46ea-8564-a75e0c265e66"){//自营
			this.mapImg=require('../../../assets/allImage/serviceNetworkStation/map_zy.png');
		}else if(this.form.netWorkType=="b6996f10-6b88-4ae6-b087-65b8c73252eb"){//运营
			this.mapImg=require('../../../assets/allImage/serviceNetworkStation/map_yy.png');
		}else if(this.form.netWorkType=="4bd9a4de-75e6-4ee9-b26d-0ce0ed400bbd"){//加油站点
			this.mapImg=require('../../../assets/allImage/serviceNetworkStation/map_jy.png');
		}else if(this.form.netWorkType=="b60e320b-0cda-481f-ad8f-9f7c40fee26d"){//停车站点
			this.mapImg=require('../../../assets/allImage/serviceNetworkStation/map_tc.png');
		}
		
      }
    },
    checkLocationPermission() {
      if (navigator.geolocation) {
        const geolocation = new BMap.Geolocation();
        geolocation.getCurrentPosition(
          this.getLocationSuccess,
          this.getLocationError
        );
      } else {
      }
    },
    GoHome() {
      this.$router.push({
        path: '/layout/index',
        query: { name: 'miao' },
      });
    },
    getLocationSuccess(position) {},
    getLocationError(error) {},
    isAuthorized(position) {
      // 判断是否已授权获取位置的逻辑
      return position.accuracy != null;
    },
    delsFn(val) {
      window.open(
        `https://map.baidu.com/?lat=${val.latitude}&lng=${val.longitude}`
      );
      // window.open(
      //   `http://api.map.baidu.com/marker?location=${val.latitude},${val.longitude}&title=标注详情&content=${val.networkName}&output=html`
      // );
      // 详情
    },
    changeFn(val) {
      this.form.netWorkType = val;
    },
    // 搜索
    async searchFn() {
      await this.GetNetworkList();
      //await this.initMap();
      // this.$store.dispatch('Dottion', this.form);
      // this.$store.dispatch('mapFn');
    },
    // 标题定位
    timeString(time) {
      const dateObj = new Date(time);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1; // 注意月份是从0开始的，所以需要加1
      const day = dateObj.getDate();
      const hours = dateObj.getHours();
      const minutes = dateObj.getMinutes();
      const seconds = dateObj.getSeconds();
      const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return formattedTime;
    },
    infoWindowClose(e) {
      this.infoWindow.show = false;
    },
    //信息窗口
    infoWindowOpen(e) {
      this.infoWindow.show = true;
    },
	//点击列表
    ListTitleFn(item) {
      this.JumpAddress(item.latitude,item.longitude,item.networkName,item.address);
    },
    JumpAddress(lat, lng, name, address) {
      let baiduUrl = `marker?location=${lat},${lng}&title=${name}&content=${address}`;
      var url = `https://api.map.baidu.com/${baiduUrl}&output=html&src=webapp.baidu.openAPIdemo`;
      window.open(url, '_blank');
    },
    // 地图
    initMap() {
      var _this = this;
      const mapContainer = _this.$refs.mapContainer;
      // 创建百度地图实例
      _this.bmap = new BMap.Map(mapContainer);

      // 获取当前位置信息
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function (result) {
        // 获取当前位置的经纬度
        var lng = result.point.lng;
        var lat = result.point.lat;
        _this.form.selfLon = lng;
        _this.form.selfLat = lat;
      });

      // 设置地图的中心点和缩放级别
      const point = new BMap.Point(108.373, 22.822);
      _this.bmap.centerAndZoom(point, 9);
      // 添加标注
      const marker = new BMap.Marker(point);
      //_this.bmap.addOverlay(marker);

      var top_left_control = new BMap.ScaleControl({
        anchor: BMAP_ANCHOR_TOP_LEFT,
      }); // 左上角，添加比例尺
      var top_left_navigation = new BMap.NavigationControl();
      //左上角，添加默认缩放平移控件 bmap.addControl(top_left_control);
      _this.bmap.addControl(top_left_navigation);
      _this.bmap.enableScrollWheelZoom(true);
      //开启鼠标滚轮缩放 // 进一步操作百度地图实例，如添加标记等
      var size = new BMap.Size(40, 40); //自定义的图标大小
      var iconOptions = {
        //offset: new BMap.Size(0, 0),
        //imageOffset: new BMap.Size(0, 0),
        anchor: new BMap.Size(20, 30), //图标的定位锚点。此点用来决定图标与地理位置的关系，是相对于图标左上角的偏移值，默认等于图标宽度和高度的中间值
      };
      var icon = new BMap.Icon(_this.imgone, size, iconOptions);
      _this.$nextTick(() => {});
      _this.networkList.forEach((item, index) => {
        //循环列表，给地图添加标注
        var locationpoint = new BMap.Point(item.longitude, item.latitude);
        var marker = new BMap.Marker(locationpoint, {
          icon: icon,
        }); // 创建标注
        // 监听标注的点击事件，并绑定点击事件处理函数
        var $labelJson = {
          networkName: item.networkName,
        };
        marker.addEventListener(
          'click',
          _this.addClickHandler($labelJson, locationpoint, marker)
        );

        // 创建信息窗口对象
        var content = `<h3 style="margin:0 0 5px 0;padding:0.2em 0;color:#2772e4;cursor: pointer;"  onClick="JumpAddress('${
          item.latitude
        }','${item.longitude}','${item.networkName}','${item.address}')">${
          item.networkName
        }</h3>
					<div class="info-text">电话：<span>${item.phone}</span> </div>
					<div class="info-text">营业时间：<span>${item.businessHours}</span></div>
					<div class="info-text">地址：<span>${item.address}</span> </div>
          <div class="info-text">服务范围：<span>${
            item.serviceScope ? item.serviceScope : '暂无数据'
          }</span> </div>
					`;
        var infoWindow = new BMap.InfoWindow(content);
        // 监听标注点击事件
        marker.addEventListener('click', function () {
          // 打开信息窗口
          _this.bmap.openInfoWindow(infoWindow, locationpoint);
        });
        _this.bmap.addOverlay(marker); // 将标注添加到地图中
        // 将标记保存到数组中以便后续使用
        _this.markers.push(marker);

        if (index === 0) {
          //数组循环到第一层执行
          _this.bmap.centerAndZoom(locationpoint, 8);
        }
      });
    },
    addClickHandler(json, locationpoint, marker) {
      // 创建信息窗口
      var infoWindow = new BMap.InfoWindow('这是一个标注的信息窗口');
      // 打开信息窗口
      this.bmap.openInfoWindow(infoWindow);
    },
    findMarkerByLatLng(latitude, longitude) {
      // 根据经纬度在标记数组中找到对应的标记对象
      return this.markers.find((marker) => {
        const position = marker.getPosition();
        return (
          position && position.lat === latitude && position.lng === longitude
        );
      });
    },
    lookDetail(data, target) {
      // this.checkPointId = data.pointId;
      this.infoWindow.show = true;
      this.infoWindow.info = data;
    },
  },
  computed: {
    //...mapState(['networkList']),
    ...mapState(['map_BaiduList']),
    // ...mapState(['mapListClassification']),
  },
};
</script>

<style lang="scss" scoped>
@import url('//at.alicdn.com/t/c/font_4068950_yjnrsrqxcw.css');
.map_img{
	width: 100%;
}
// 地图导航
.profilephotoNav {
  width: 100%;
  height: 3.125rem;
  background-color: #4b4843;
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: center;

  .proSon {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    color: #c1c1c1;
    padding-left: 15px;

    img {
      width: 0.9375rem;
      vertical-align: middle;
      margin-right: 0.1875rem;
    }
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.info-text {
  font-size: 16px;
  margin: 10px 0;
  width: 500px;

  span {
    color: #333;
  }
}

.app {
  width: 100%;

  .picmsg {
    width: 100%;
    margin-bottom: 0.9375rem;
  }

  .app-content {
    width: 80%;
    margin: 0 auto;

    .searchSlit {
      width: 100%;
      text-align: left;
      margin-bottom: 20px;
    }

    .ma-_content {
      width: 100%;
      display: flex;

      .map_query {
        width: 18.75rem;
        height: 47.375rem;
        overflow: hidden;
        overflow-y: auto;

        .businessType {
          width: 17.5rem;
          background-color: #eff8ff;
          border-radius: 0.25rem;
          margin: 0.625rem auto;
          padding: 0.625rem;
          text-align: left;

          .Nav_title {
            color: #0a3cf1;
            padding: 0.625rem 0;
            border-bottom: 0.0625rem dashed #c1c1c1;

            .pause {
              padding: 0.3125rem 0.625rem;
              color: #fff;
              font-size: 0.75rem;
              border-radius: 0.25rem;
              margin-left: 0.3125rem;
              overflow-wrap: break-word;
            }
          }

          .add_parent {
            width: 100%;
            margin-top: 0.625rem;
            font-size: 0.75rem;

            .adds {
              width: 100%;
              padding: 0.3125rem 0;
            }
          }
        }
      }

      .Maps {
        flex: 1;
        padding: 0.625rem;

        .map-wrap {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
